@import '../../assets/style/variables';

.modalContainer {
    min-width: 400px;
    max-width: 600px;

    h6 {
        max-width: 450px;
        margin-bottom: 16px;

        .assetName {
            color: $primary-color;
        }
    }

    :global(.autoWidth) {
        width: auto;
    }

    .container {
        :global(.MuiInput-root),
        :global(.MuiButton-root) {
            border-radius: 8px;
            min-height: 40px;
            height: 40px;
        }
        :global(.MuiSelect-select) {
            padding: 8px;
        }

        form {
            padding: 0;
            padding-top: 16px;
        }

        .divider {
            margin: 0 -32px;
            border-color: $primary-border-color;
        }
    }
}

@import '../../assets/style/variables';

.headerTopOverwrite {
    > div {
        top: 63px !important;
    }
}

.pageContainer {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $primary-border-color;
    background-color: #fbfcff;
    display: flex;
    flex-direction: column;
    position: relative;

    .assetContainer {
        flex-grow: 2;

        :global(.genericTableBody) {
            overflow: auto;
            max-height: calc(100vh - 365px);
            border-right: 0;
            border-left: 0;
        }

        :global(.tableFooterContainer) {
            display: none;
        }
    }
}

.toolbar {
    justify-content: flex-start !important;
    border-bottom: 1px solid $primary-border-color !important;

    > span {
        width: initial !important;
        padding: 0 40px;
    }
}

.subHeaderContainer {
    border-bottom: none !important;
}

.assets {
    display: flex;
    align-items: center;
    margin-left: 8px;

    color: $primary-text-color;

    &:first-of-type {
        margin-left: 8px;
    }

    svg {
        margin-right: 8px;
    }
}

.header {
    svg {
        fill: $default-error-color !important;

        > * {
            fill: $default-error-color !important;
        }
    }
}

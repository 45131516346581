@import '../../assets/style/variables';

.modalContainer {
    width: 600px;
    @media (max-width: 1000px) {
        width: 700px;
    }
}

.container {
    .title {
        margin-bottom: 30px;
    }

    max-width: 600px;

    :global(.MuiInputBase-root) {
        max-height: 40px;
        padding: 0 !important;
        padding-left: 8px !important;
    }
}

@import '../../assets/style/variables';

.toolbarContainer {
    display: flex;
    align-items: center;

    height: 32px;
}

.button {
    height: 32px;
    width: 32px;
    min-width: initial !important;

    border-radius: 8px !important;
    border: 1px solid $primary-border-color !important;
    background-color: $white-color !important;

    padding: 8px !important;
    margin-right: 8px !important;

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        background-color: $light-border-color !important;
    }

    &.active {
        background-color: $primary-color !important;
        svg {
            path {
                fill: $white-color;
            }
        }
    }
}

@import '../../assets/style/variables';

.dotsMenuBox {
    width: 100%;
    display: flex;
    align-items: center;
}

.wrapper_logo {
    display: flex;
    align-items: center;
    height: 100%;

    .logo {
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    .arrow {
        margin: 0 16px;

        &.faded {
            opacity: 0.5;
        }
    }

    .module {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;

        .menuIcon {
            position: relative;
        }

        svg {
            cursor: pointer;
        }

        .bg {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100vh;
            width: 100vw;
            z-index: 2;
            //background-color: grey;
        }

        .dropdownContainer {
            z-index: 2;
            padding: 8px;
            position: absolute;
            top: 7px;
            left: -8px;
            border-radius: 8px;
            box-shadow: 0px 10px 40px 0px #2c3e5040;
            background-color: white;
            width: 165px;

            .row {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 10px;
                justify-content: space-between;

                &:hover {
                    background-color: #e6eefe;
                    border-radius: 8px;
                }

                svg {
                    margin-right: 8px;
                    position: relative;
                    top: -4px;
                }

                span {
                    color: $primary-color;
                    display: flex;
                    align-items: center;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                .dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: $primary-border-color;
                    margin-right: 8px;

                    &.active {
                        background-color: $succes-color;
                    }
                }

                .pt1 {
                    position: relative;
                    top: 1px;
                }
            }
        }
    }

    .select {
        background-color: transparent !important;

        min-width: 130px;
        transition: all 228ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border: none !important;
        z-index: 1 !important;

        :global(.MuiSelect-icon) {
            display: none;
        }

        :global(.MuiSelect-select) {
            display: flex;
            padding: 0 !important;
            height: 40px;
            box-sizing: border-box;
        }

        .link {
            //12 is the margin to the image, 6 is the width of the circle, 16px is the margin to border
            margin-right: calc(12px + 6px + 16px);
        }

        .circle {
            display: none;
        }

        &.selectOpened {
            border-radius: 8px;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            background-color: white !important;
            // box-shadow: 0px 10px 40px rgba(44, 62, 80, 0.25) !important;

            .circle {
                display: block;
            }

            .link {
                margin: 16px;
                z-index: 1;
            }
        }

        fieldset {
            border: none;
        }
    }
}

.wrapper_dots {
    border: 1px solid $primary-border-color;
    border-radius: 8px;
    box-shadow: 0px 4px 10px $light-border-color;

    :global(.MuiTooltip-popper) {
        margin-top: 8px !important;
        width: 200px;

        > div {
            margin: 0;
            padding: 0;
        }
    }

    .menuBtn {
        &:hover {
            cursor: pointer;

            &:global(.MuiTypography-root) {
                color: $primary-hover-color;
            }
        }
    }

    .tooltip {
        background: $white-color;
        box-shadow: 0px 4px 10px $light-border-color;
        border-radius: 8px;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .tooltipContainer {
        padding: 12px;

        .row {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            a {
                width: 100%;
                position: relative;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .label {
                margin-left: 12px;
                color: $primary-color;
            }

            .icon {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                position: relative;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            .badge {
                position: absolute;
                right: 30px;
            }

            &:hover {
                cursor: pointer;
                background-color: #e6eefe;
                border-radius: 8px;

                .icon {
                    border: none;
                }
            }

            &.disabled {
                cursor: default;
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    .open {
        background-color: $primary-color;

        > svg {
            path {
                fill: $white-color;
            }
        }
    }
}

.wrapper {
    width: 100vw;
    height: $header-height;

    padding: 0 20px;
    border-radius: 16px;
    background: $header-background-color;

    z-index: 1;
    position: fixed;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .container {
        display: flex;
        gap: 12px;

        .expanded {
            &.rotate {
                rotate: 180deg;
                margin-right: 0;
            }
        }

        .nonExpanded {
            background-color: transparent;
            animation: maxBack 0.15s ease 1 backwards !important;

            border: 0 !important;
            margin: 0 !important;

            .underscore {
                display: none;
            }

            svg {
                display: none;
            }
        }

        .rowContainer {
            display: flex;
            align-items: center;

            .menuItem {
                background-color: $white-color;
                border: 1px solid $primary-border-color;

                border-radius: 8px;

                width: 40px;
                min-width: 40px;
                height: 40px;

                &:hover {
                    cursor: pointer;
                }

                &.disabled {
                    cursor: default;
                    pointer-events: none;
                    opacity: 0.5;
                }

                &.active {
                    background-color: $primary-color;

                    svg,
                    svg path {
                        filter: brightness(8);
                    }
                }

                .menuIcon {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 16px;
                    }
                }
            }
        }

        .childrenContainer {
            width: 0%;
            background-color: $white-color;
            border: 1px solid $primary-border-color;
            animation: maxOff 0.25s ease 1 forwards;
            opacity: 0;

            border-radius: 8px;

            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            .childrenMenuItem {
                margin: 0 8px;
                height: 100%;
                position: relative;

                &:hover {
                    cursor: pointer;
                }

                &.disabled {
                    cursor: default;
                    pointer-events: none;
                    opacity: 0.5;
                }

                .underscore {
                    height: 3px;
                    width: 20px;
                    position: absolute;
                    bottom: -2px;
                    background: $primary-color;
                    border-radius: 8px;
                }

                .menuIcon {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 16px;
                    }
                }
            }

            .margin0 {
                margin: 0;
            }
        }
    }

    .rightContainer {
        .right {
            display: flex;
            align-items: center;
        }

        .tooltip {
            background: $white-color;
            border: 1px solid #d5e3ff;
            box-shadow: 0px 4px 10px $light-border-color;
            border-radius: 8px;
            height: 34px;
            width: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .open {
            background-color: $primary-color;

            svg {
                path {
                    fill: $white-color;
                }
            }
        }
    }
}

.mdIcon {
    fill: $primary-color;
}

@keyframes createBox {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes maxOff {
    to {
        width: 100%;
        opacity: 1;
    }
}

@keyframes maxBack {
    from {
        width: 100%;
        opacity: 1;
    }
}

@import '../../../assets/style/variables';

.authWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.changePassword {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: $primary-font-family;
    max-width: 825px;
    margin: auto;
    width: 100%;
}

.illustration {
    margin-right: 30px;

    img {
        width: 306px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .logoContainer {
        margin-bottom: 40px;
        margin-top: 40px;

        .logo {
            width: 116px;
        }
    }

    .container {
        border: 1px solid transparent;
        padding: 50px 48px 42px;
        background: $default-light-background;
        border-radius: 20px;

        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 27.65px;
            line-height: 27px;
            margin: 0 0 40px;
            color: $primary-color;
        }
    }
}

.options {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 24px;

    span {
        font-family: $primary-font-family;
        font-style: normal;
        line-height: 27px;
        font-weight: 700;
        font-size: 13px;

        color: $primary-color;
    }
}

@media screen and (max-width: 840px) {
    .changePassword {
        flex-direction: column-reverse;
        align-items: center;
    }

    .illustration {
        margin: 40px 0 24px 0;
    }

    .content {
        max-width: 375px;
        width: 100%;

        .container {
            padding: 50px 16px;
            width: 100%;

            h2 {
                font-size: 24px;
            }
        }
    }
}

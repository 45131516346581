@import '../../assets/style/variables';

.header {
    padding: 20px 20px 20px 40px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom: 1px solid $primary-border-color;
    color: $primary-text-color;
    background-color: $default-light-background;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .beta {
        color: $default-error-color;
    }
}

.container {
    background-color: $default-light-background;
}

.grid {
    .itemWrapper {
        background: #fbfcff;
        border: 1px solid $primary-border-color;
        box-shadow: 0px 4px 10px $light-border-color;
        border-radius: 8px;

        .itemHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;

            color: $primary-color;

            padding: 20px;

            border-bottom: 1px solid $primary-border-color;

            .name {
                display: flex;
                align-items: center;

                &:hover {
                    cursor: pointer;
                }

                &.black {
                    color: $primary-text-color;
                }

                > svg {
                    margin-right: 12px;
                }
            }

            .actions {
                display: flex;
                align-items: center;

                button {
                    padding: 0;

                    svg {
                        color: $primary-color;
                        background: $light-border-color;

                        padding: 2px;
                        font-size: 18px;

                        &:hover {
                            cursor: pointer;
                        }

                        &:first-of-type {
                            margin-right: 4px;
                        }
                    }
                }
            }

            .icon {
                fill: $default-error-color !important;

                > * {
                    fill: $default-error-color !important;
                }
            }
        }

        .itemDetails {
            display: flex;
            align-items: center;

            border-bottom: 1px solid $primary-border-color;

            &.noBorder {
                border-bottom: none;
            }
            &.hidden {
                display: none;
            }

            .element {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary-text-color;

                &.assetRow {
                    justify-content: flex-start;
                    margin-left: 20px;
                }

                .black {
                    color: $primary-text-color;
                }

                .blue {
                    color: $label-text-color;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }

                &.border {
                    border-right: 1px solid $primary-border-color;
                }
            }
        }

        .itemChartContainer {
            $card-header-height: 65px;
            $card-details-height: 41px;

            height: calc(100% - $card-header-height);
            &.vppChart {
                height: calc(100% - $card-header-height);
            }

            > span {
                display: block;
                padding: 10px 20px;
            }

            .itemChart {
                height: calc(100% - 36px);

                .legend {
                    position: absolute;
                    left: 16px;
                    @media screen and (max-width: 1200px) {
                        left: 8px;
                    }
                    @media screen and (min-width: 1600px) {
                        left: 24px;
                    }
                    top: 80px;
                }

                .pieChart {
                    position: absolute;
                    right: 24px;
                    @media screen and (max-width: 1200px) {
                        right: 8px;
                    }
                    @media screen and (min-width: 1600px) {
                        right: 48px;
                    }
                    height: 100%;
                }
            }

            .p12 {
                padding-right: 12px;
                padding-left: 12px;
            }

            .lineChart {
                padding-right: 12px;
                margin-left: -24px;
                @media (max-width: 1100px) {
                    margin-left: -16px;
                }
            }
        }

        .itemStaticDetailsWrapper {
            display: flex;
            justify-content: space-between;

            margin: 32px 32px 0;

            .itemStaticDetails {
                > div {
                    margin-bottom: 32px;
                }

                .itemStaticBox {
                    display: flex;
                    align-items: center;

                    > div {
                        display: flex;
                        align-items: baseline;

                        span {
                            margin-left: 4px;
                        }
                    }

                    svg {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

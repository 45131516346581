@import '../../assets/style/variables';

/* hide input number arrows start */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
/* hide input number arrows end */

.MuiSelect-select {
    display: flex;
}

.deliveryMethod {
    display: flex;
    flex-direction: column;
    .deliveryOption {
        width: 100px;
        height: 55px;
        &:global(.selected) {
            border-color: $primary-color !important;
            color: $primary-color;
        }
        &:global(:hover) {
            border-color: $primary-hover-color !important;
            color: $primary-hover-color;
        }
    }
}

.container {
    :global(.MuiInputBase-root) {
        border-radius: 8px;
        min-height: 40px;
        height: 40px;
    }

    hr {
        margin: 20px -32px;
        border-color: $primary-border-color;
    }

    .formInput {
        margin-bottom: 16px;
        position: relative;
        min-width: 40%; /* small *2 + 2 * spacing */
        width: 100%;

        /*&:last-of-type {
            margin-bottom: 0;
        }*/

        &.small {
            width: 100%;
            min-width: 20%;

            // @media (max-width: 1160px) {
            //     min-width: 147px;
            // }
        }

        &.extraSmall {
            :global(.MuiOutlinedInput-root) {
                width: 100%;
                min-width: 20%;

                // @media (max-width: 1160px) {
                //     min-width: 147px;
                // }
            }
        }

        .pinIcon {
            position: absolute;
            right: 0;
            top: 34px;
            width: 28px;
            height: 28px;
        }

        :global(.MuiInput-root) {
            border-color: $primary-border-color;
        }

        :global(.MuiInput-input) {
            //@include typography-small1;
            font-style: normal;
            font-weight: 400;
            font-size: rem(14px) !important;
            line-height: rem(18px) !important;
            /* end */
            padding-bottom: 8px;
            padding-top: 8px;
        }
    }

    .phoneNumberInput {
        width: 100%;
    }

    :global(.MuiSelect-select) {
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    :global(.MuiInputBase-root),
    :global(.MuiButton-root) {
        min-height: 40px;
        height: 40px;
        border-radius: 8px;
        input {
            padding: 0 !important;
        }
    }
}

.svgImg {
    /* override the global styles */
    margin-right: 0 !important;
}
.noCalendarIcon {
    :global(.MuiInputAdornment-root) {
        display: none;
    }
}
.calendarInput {
    :global(.MuiInput-input) {
        letter-spacing: 2px;
        text-transform: uppercase;
    }
}
.dropdownMenuItem {
    padding-top: 8px;
    padding-bottom: 8px;
}

.ml1 {
    margin-left: 8px !important;
}

@import '../../assets/style/variables';

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 525px;
    width: 100%;
    margin: 0 auto;

    .content {
        max-width: 370px;
        text-align: center;

        h1 {
            color: $label-text-color;
            padding: 32px 0;
        }
    }

    img {
        max-width: 240px;
        margin: 35px 0;
        float: left;
    }
}

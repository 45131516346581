@import '../../assets/style/variables';

.container {
    min-width: 500px;
    :global(.MuiInput-root),
    :global(.MuiButton-root) {
        border-radius: 8px;
        min-height: 40px;
        height: 40px;
    }

    h6 {
        max-width: 450px;
        margin-bottom: 16px;
    }

    .assetName {
        color: $primary-color;
    }

    .mb2 {
        margin-bottom: 16px;
    }

    .w500 {
        max-width: 500px;
    }

    .row {
        display: flex;
        justify-content: space-between;
    }

    form {
        padding: 0;
        padding-top: 16px;
    }

    .divider {
        margin: 0 -32px;
        border-color: $primary-border-color;
    }

    :global(.MuiButtonBase-root.Mui-disabled) {
        background: $white-color;
        border: 1px solid $light-blue;
        box-shadow: 0px 4px 10px $light-border-color;
        color: $primary-border-color;
    }

    .emailInput {
        position: relative;
        margin-bottom: 8px;

        .icon {
            position: absolute;
            right: 8px;
            top: 8px;
            width: 22px;
            height: 22px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

@import '../../assets/style/variables';

.mapContainer {
    width: 100vw;
    height: calc(100vh - 63px);
    margin: -12px;

    // Hide bottom-left Google logo
    a[aria-label='Open this area in Google Maps (opens a new window)'] {
        display: none !important;
    }

    // Hide bottom-right terms navbar
    :global(.gmnoprint),
    :global(.gm-style-cc) {
        display: none;
    }

    // Hide blue focus border
    :global(.gm-style) {
        iframe + div {
            border: none !important;
        }
    }
}

.cluster {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    color: #ffffff;
    background: $primary-color;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.marker {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
}

.markerIcon {
    position: relative;

    &:hover {
        cursor: pointer;
    }

    .iconBase {
        width: 100%;
        height: 100%;
    }

    .noTip {
        g ~ path {
            display: none;
        }
    }

    .currentIcon {
        position: absolute;
        top: 0;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: auto;
    }

    &.markerIconActive {
        .iconBase {
            rect,
            path {
                fill: #3745f2;
            }
        }

        .currentIcon {
            path {
                fill: #ffffff;
            }
        }
    }
}

.tooltip {
    font-family: 'Montserrat';
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    min-width: max-content;
    margin: auto;
    bottom: 55px;
    border-radius: 8px;
    border: 1px solid $light-blue;
    z-index: 1;

    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        border-bottom: 1px solid $default-background;
        padding: 13px 9px 10px 9px;

        .id {
            color: $primary-color;
            font-size: 14px;
            font-weight: bold;
        }

        .charge {
            color: $label-text-color;
            font-size: 12px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 7px;
        padding: 7px 16px;

        .item {
            display: flex;
            flex-direction: column;
            gap: 3px;

            .itemTitle {
                position: relative;
                color: $label-text-color;
                font-size: 12px;
                padding-left: 17px;

                &::before {
                    content: ' ';
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    background-color: $primary-border-color;
                }
            }

            .info {
                color: $primary-color;
                font-size: 12px;
                font-weight: bold;
                padding-left: 17px;
            }

            .option {
                color: $primary-color;
                font-size: 12px;
                font-weight: bold;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        border-top: 1px solid $default-background;
        padding: 13px 9px 10px 9px;
    }
}

.semicircleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    span {
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        color: #323232;
        word-wrap: break-word;
        width: 60px;
        text-align: center;
    }

    .semicircle {
        @property --percentage {
            syntax: '<number>';
            inherits: true;
            initial-value: 0;
        }

        &[role='progressbar'] {
            --percentage: var(--value);
            --primary: var(--primary);
            --secondary: #d5e3ff;
            --size: 55px;
            animation: progress 2s 0.5s forwards;
            width: var(--size);
            aspect-ratio: 2 / 1;
            border-radius: 50% / 100% 100% 0 0;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        &[role='progressbar']::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: conic-gradient(
                from 0.75turn at 50% 100%,
                var(--primary) calc(var(--percentage) * 1% / 2),
                var(--secondary) calc(var(--percentage) * 1% / 2 + 0.1%)
            );
            mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
            mask-mode: alpha;
            -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
            -webkit-mask-mode: alpha;
        }

        &[role='progressbar']::after {
            counter-reset: percentage var(--value);
            content: counter(percentage);
            font-family: 'Montserrat';
            font-size: calc(var(--size) / 5);
            color: var(--primary);
        }
    }
}

.layers {
    // cursor: pointer;
    position: absolute;
    top: 10px;
    right: 0px;

    .layersTooltip {
        position: absolute;
        bottom: -92px;
        left: -188px;
        width: 170px;

        .content {
            padding: 10px 10px;
            gap: 10px;
        }

        .icon {
            width: 25px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            svg {
                width: 20px;
                height: 18px;
            }
        }

        .itemContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 12px;

            &:hover {
                cursor: pointer;
            }
        }

        .item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .toggle {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #d9d9d9;

        &.active {
            background-color: #3745f2;
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

.disabled {
    cursor: default !important;
    pointer-events: none !important;
    opacity: 0.5;
}

@import '../../assets/style/variables';

.legendItem {
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-of-type {
        margin-right: 0;
    }

    .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 8px;

        &.forecast {
            background-color: $succes-color;
        }

        &.actual {
            background-color: $label-text-color;
        }

        &.regularization {
            background-color: $default-badge-color;
        }

        &.demand {
            background-color: $default-avatar-color;
        }

        &.measuredProfiled {
            background-color: rgba(106, 155, 255, 0.77);
        }
        &.forecastedCluster {
            background-color: rgba(111, 216, 178, 0.63);
        }
    }
}
.chartContainer {
    position: relative;
    flex-grow: 2;

    .dataLoading {
        width: 100%;
        height: 100%;
    }

    .chartHeader {
        height: 32px;
        position: absolute;
        z-index: 3;
        align-items: center;
        background-color: $white-color;
        padding: 12px 24px;
        border-radius: 8px;
        top: 24px;
        left: 40px;
        display: flex;
        box-shadow: 0px 4px 10px $light-border-color;
    }

    .chart {
        height: 100%;

        & > div {
            height: 100%;
        }
    }

    .noBottomBorder {
        border-bottom: none;
    }
}

@import '../../assets/style/variables';
.assets {
    display: flex;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;

    border: 1px solid $primary-border-color;
    background-color: white;
    border-radius: 8px;
    padding: 4px;

    color: $primary-text-color;

    &.active {
        background-color: $light-border-color;
    }

    :global(.MuiCheckbox-root) {
        padding: 1px;
    }
}

@import '../../assets/style/variables';

.titleIcon {
    path {
        fill: $default-avatar-color;
    }
}

.info {
    color: $primary-color;
    path {
        stroke: $primary-color;
    }
}
.success {
    color: $succes-color;
    path {
        stroke: $succes-color;
    }
}
.warning {
    color: $pending-color;
    path {
        stroke: $pending-color;
    }
}
.error {
    color: $default-error-color;
    path {
        stroke: $default-error-color;
    }
}

.disabled {
    color: $disabled-color;
    path {
        stroke: $disabled-color;
    }
}

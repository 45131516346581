/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    border: none;
    transition: none;
    -webkit-box-shadow: 0 0 0 30px $white-color inset !important;
}

/* input type date styling */
::-webkit-datetime-edit-text {
    color: $default-border-color;
    padding: 4px;
}
/* end of input type date */

// Tables
.TableSearchHighlightResult {
    background: $default-border-color;
    padding: 8px 0;
    border-radius: 8px;
}

// Scrollbar
*::-webkit-scrollbar {
    border-radius: 50px;
    height: 8px;
    width: 8px;
}

*::-webkit-scrollbar-track {
}

*::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    border-radius: 50px;
    outline: none;
}

.lightBg {
    border-radius: 16px;
    background-color: rgba(248, 251, 255, 0.8) !important;
}

/* OGRE-653 auto-hide scrollbar */
.hiddenScroll::-webkit-scrollbar {
    background: white;
    opacity: 1;
}

.hiddenScroll::-webkit-scrollbar-thumb {
    background: transparent;
}

.fullSizeLoading {
    height: 100%;
    width: 100%;
}

body {
    overflow-y: auto;
}
/* end */

html {
    font-size: $html-font-size;

    @media screen and (max-width: $breakpoint-lg) {
        font-size: 14px;
    }
}

.flagIcon {
    border-radius: 8px;
}

.solidHeaderBg {
    background: #f7faff !important;
}

/* styling for modals who want 100% width*/
#createVPP,
#editVPP {
    .MuiPaper-root {
        width: 100%;
    }
}

// .MuiMenu-paper {
//     border: 1px solid #d5e3ff;
//     border-top: 0;
//     margin-top: 4px;
//     box-shadow: 0px 10px 40px rgb(44 62 80 / 25%) !important;
// }

// #menu-day_ahead_forecast_settings\.aggregation_level {
//     .MuiMenu-paper {
//         margin-top: -8px;
//     }
// }

// #menu-windTracking,
// #menu-installationTypeWind,
// #menu-solarTracking,
// #menu-installationTypeSolar {
//     .MuiMenu-paper {
//         margin-top: -6px;
//     }
// }

// #menu-type,
// #menu-day_ahead_forecast_settings\.aggregation_level,
// #menu-day_ahead_forecast_settings\.time_resolution_mins,
// #menu-day_ahead_forecast_settings\.real_time_data,
// #menu-intraday_forecast_settings\.aggregation_level,
// #menu-long_term_forecast_settings\.aggregation_level,
// #menu-long_term_forecast_settings\.time_resolution_mins,
// #menu-long_term_forecast_settings\.real_time_data,
// #menu-intraday_forecast_settings\.time_resolution_mins,
// #menu-intraday_forecast_settings\.real_time_data {
//     .MuiMenu-paper {
//         margin-top: -8px;
//     }
// }

.borderHeader {
    border-top: 1px solid $primary-border-color;
    border-radius: 0;
}

.autocompleteRow {
    margin: 2px 8px;
    padding: 4px 8px;
    overflow: hidden;
    border-radius: 8px;
    @include typography-small1;
    &:hover {
        background: $default-background;
        cursor: pointer;
    }

    &.isSelected {
        background: $default-background;
    }
}

.MuiAutocomplete-option {
    padding: 4px 8px !important;
    margin: 2px 8px !important;
    border-radius: 8px;
    @include typography-small4;
    &:hover {
        cursor: pointer;
        background: $light-border-color !important;
    }

    &[aria-selected='true'] {
        background: $light-border-color !important;
    }
    &.Mui-focused {
        background: $light-border-color !important;
    }
}

.MuiMenuItem-root.Mui-selected:hover {
    background-color: $light-border-color !important;
}

.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select {
    @include typography-small1;
}

.MuiButtonBase-root.MuiMenuItem-root {
    @include typography-small4;
}

.m-r-1 {
    margin-right: 8px;
}

.m-r-2 {
    margin-right: 16px;
}

.modulesContainer {
    display: flex;
    align-items: center;
    padding-left: 20px;

    > * {
        margin-right: 8px;
        width: 18px;
        height: 18px;
    }
}

a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.MuiPopover-paper {
    box-shadow: 0px 16px 100px #e7ebed !important;
}

.wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        width: 24px;
        height: 24px;
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        rect {
            width: 24px;
            height: 24px;
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

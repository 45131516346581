@import '../../assets/style/variables';

.modalContainer {
    width: 420px;
    height: auto;
    padding: 32px;
    hr {
        border-color: $default-border-color;
        margin: 0 -32px;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        // make room for the close btn
        margin-right: 20px;
    }

    .mapContainer {
        position: relative;
        margin-top: 20px;
        height: 264px;
    }

    .body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .mt10 {
            margin-top: 10px;
        }

        .center {
            display: flex;
            align-items: center;
        }

        .primary {
            color: $primary-color;

            svg {
                margin-right: 8px;

                path {
                    fill: $primary-color;
                }
            }
        }
    }
}
.fullScreenMapContainer {
    position: relative;
    top: 40px;
    height: calc(100% - 40px);
}
.btnWrapper {
    width: 32px;
    height: 32px;
    background: $white-color;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
    right: 16px;

    :hover {
        cursor: pointer;
    }
}
.closeButton {
    top: 24px !important;
}

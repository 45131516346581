@import '../../../assets/style/variables';

.editActionStyling {
    svg {
        color: $label-text-color;
    }
}

.on {
    fill: $succes-color !important;
}

.off {
    fill: $default-error-color !important;
}

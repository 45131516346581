@import '../../assets/style/variables';
.content {
    align-items: center;
}
.wrapper {
    border: 1px solid $default-border-color;
    border-radius: 8px !important;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: none !important;

    &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    box-shadow: none;

    .detailsRoot {
        padding: 8px 8px 8px 16px;
    }

    .summaryRoot {
        min-height: 40px;

        img {
            width: 20px;
            height: 20px;
            margin-right: 16px;
        }
    }

    .summaryExpanded {
        margin: 0;
    }

    .permission {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        .label {
            display: flex;
            align-items: center;
            width: calc(100% - 32px);
            margin-bottom: 0;
            color: $label-text-color;

            .bold {
                font-weight: 700;
            }

            &.disabledLabel {
                &:hover {
                    cursor: initial;
                }
            }

            &:hover {
                cursor: pointer;
            }

            .icon {
                width: 18px;
                height: 18px;
                margin-right: 20px;

                filter: invert(55%) sepia(21%) saturate(1273%) hue-rotate(184deg) brightness(105%) contrast(101%);
            }
        }

        :global(span.Mui-checked ~ .MuiInputLabel-root) {
            color: $primary-color !important;
            img {
                filter: initial !important;
            }
        }
    }
}

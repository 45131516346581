@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.container {
    height: 100%;
    padding: 0 40px 20px;
}

// this needs to stay at the root because the events from "show more" panel are rendered at the body level
.calendarEventContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include calendar-tablet {
        span {
            @include typography-small2;
        }
    }

    .calendarEventLeft {
        display: flex;
        align-items: center;

        .bar {
            width: 8px;
            height: 18px;
            background-color: $default-border-color;
            border-radius: 2px;
            margin-right: 8px;
        }
    }

    .label {
        color: $secondary-text-color;
    }
}

.calendarToolbarContainer {
    margin: 0 -40px;
    padding: 20px 40px;

    @include calendar-tablet {
        padding: 12px 40px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
        color: $primary-text-color;
    }

    .calendarToolbarActions {
        display: flex;
        align-items: center;

        button {
            height: 32px;
            width: 32px;
            padding: 8px;
            border-radius: 8px;
            background-color: transparent;

            &:first-of-type {
                height: 32px;
                width: 32px;
                // padding: 8px 20px;
                background-color: transparent;
                color: $primary-color;
            }

            &:not(:first-of-type) {
                padding: 0;
            }

            &:last-of-type {
                margin-left: 8px;
            }

            &:not(:first-of-type):hover {
                svg {
                    fill: $primary-color;
                }
                border: 1px solid $primary-border-color;
            }

            svg {
                fill: $primary-color;
            }
        }
    }
}

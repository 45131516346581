@import '../../assets/style/variables';

.modalContainer {
    .title {
        padding-top: 8px;
        margin-bottom: 20px;
    }

    .customBodyTableClassName {
        display: flex;
        justify-content: space-between;
        .table {
            width: calc(50% - 36px);

            .nameContainer {
                :global(.MuiInput-root) {
                    height: 40px;
                    min-height: 0;
                    border-radius: 8px;
                }
            }

            .searchInput {
                margin-bottom: 20px;
                > div {
                    min-height: 0;
                    height: 40px;
                    border-radius: 8px;
                    margin-top: 20px;
                }
            }

            .tableContainer {
                border: 1px solid $default-border-color;
                border-radius: 8px;
                height: auto;
                :global(.genericTableRow) {
                    margin: 0;
                }
            }

            .footerLabel {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 16px;
            }
        }

        .toolbar {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 166px;
            position: absolute;
            left: calc(50% - 36px);

            .actionBtn {
                margin: 8px 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                cursor: pointer;
                border: 1px solid $primary-border-color;
                border-radius: 8px;
                &:hover {
                    background-color: $light-border-color;
                }
            }
        }
        :global(.tableFooterContainer) {
            display: none;
        }
        :global(.genericTableHeader) {
            border-radius: 8px;
            > div {
                border: 0 !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
                border-radius: 8px;
                margin-bottom: 0 !important;
            }
        }
        :global(.genericTableBody) {
            max-height: 200px;
            min-height: 200px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            padding-left: 8px;
        }
    }

    :global(.FormModalActions) {
        margin-top: 20px;
        margin-bottom: -4px;
        :global(.MuiButton-root) {
            border-radius: 8px;
            min-height: 0;
            height: 40px;
        }
    }
}

.mt {
    margin-top: 64px;
    @media screen and (max-width: $breakpoint-lg) {
        margin-top: 62px;
    }
}

.disabled {
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
}

@import '../../assets/style/variables';

.modalContainer {
    width: 900px;
    @media (max-width: 1000px) {
        width: 700px;
    }
}

.container {
    .title {
        margin-bottom: 30px;
    }
    .formInput {
        margin-bottom: 16px;
        position: relative;
        width: 100%;

        :global(.MuiInputBase-root) {
            min-height: 40px;
            height: 40px;
            border-radius: 8px;
            border-color: $primary-border-color !important;
            padding-left: 16px;

            input {
                padding: 0 !important;
            }
        }

        &.select {
            :global(.MuiInputBase-root) {
                padding-left: 0;
            }
        }

        &.calendar {
            :global(.MuiIconButton-root) {
                display: none;
            }
        }

        :global(.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select) {
            display: flex;
            align-items: center;
            padding: 10px;
            padding-left: 16px;
        }
    }

    hr {
        margin: 20px -32px;
        margin-top: 4px;
        border-color: $default-border-color;
    }
}

.ml1 {
    margin-left: 10px !important;
}

.dropdownMenuItem {
    path {
        fill: $primary-color;
    }
}

.rbc-calendar {
    .rbc-button-link {
        @include typography-small1;
    }

    .rbc-show-more {
        @include typography-small1;

        color: $default-input-text-color !important;

        text-align: center;
        width: 100%;

        @include calendar-tablet {
            @include typography-small2;
        }
    }

    .rbc-month-view {
        border: none;
    }

    .rbc-day-bg {
        border: 1px solid $default-border-color !important;
        border-radius: 8px;
        background: $white-color;
    }

    .rbc-month-row + .rbc-month-row {
        border-color: $default-border-color;
        border-radius: 8px;
    }

    .rbc-month-row {
        border-radius: 8px;
        border: none !important;
    }

    .rbc-header {
        text-align: left;
        border: none;
        padding: 4px 0;
        border: none !important;
    }

    .rbc-date-cell {
        @include typography-small1;
        text-align: left;
        padding: 8px;

        @include calendar-tablet {
            padding: 2px 4px;
            @include typography-small2;
        }

        .rbc-button-link {
            @include calendar-tablet {
                @include typography-small2;
            }
        }
    }

    .rbc-event,
    .rbc-day-slot .rbc-background-event {
        @include calendar-tablet {
            padding: 0 5px;
        }
    }
}

.rbc-event {
    @include typography-small1;

    background-color: transparent !important;
    color: $primary-text-color !important;

    &:focus {
        outline: none !important;
    }

    &:hover {
        cursor: initial;
    }
}

body > .rbc-overlay {
    @include box-shadow-drop1;

    min-width: 200px !important;
    border: 1px solid $default-border-color;
}

.rbc-overlay-header {
    @include typography-small1;

    border-bottom: 1px solid $default-border-color !important;
    padding: 8px 16px !important;
}

@import '../../assets/style/variables';

.wrapper {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: relative;

    background: $default-light-background;

    border: 1px solid $primary-border-color;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

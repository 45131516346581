@import '../../assets/style/variables';

.container {
    flex-grow: 2;
    display: flex;
    flex-direction: column;

    .table {
        /* overwrite basic styling from generic table */
        :global(.tableHeaderCell) {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
        }

        :global(.genericTableBody) {
            overflow: auto;
            max-height: calc(100vh - 280px);
            border-right: 0;
            border-left: 0;
        }

        :global(.genericTableHeader) {
            & > div {
                border-radius: 0;
                border-left: 0;
                border-right: 0;
                border-top: 0;
            }
            // so scroll can work on the table
            top: 0;
        }
    }

    :global(.tableFooterContainer) {
        padding: 0;
    }
}

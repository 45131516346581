@import '../../assets/style/variables';

.box {
    width: 455px;
    > div {
        width: auto;
        position: relative;
    }
    h6 {
        margin-bottom: 20px;
    }

    hr {
        border-color: $default-border-color;
        margin: 0 -32px;
    }

    .formInput {
        margin-bottom: 20px;
        &.smallInput {
            display: flex;
            justify-content: space-between;
            > div {
                width: 48%;
            }
        }
    }

    :global(.MuiInputLabel-root) {
        margin-bottom: 4px;
    }
    :global(.MuiButton-root) {
        border-radius: 8px;
        height: 40px;
        min-height: 40px;
        width: 120px;
    }
    :global(.MuiInput-root) {
        border-radius: 8px;
        height: 40px;
        min-height: 40px;
    }
}

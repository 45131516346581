@import '../../../assets/style/variables';

.noValueTable {
    width: 100%;
    height: 100%;
}

.loadingRow {
    height: 48px;
    display: flex;
    align-items: center;
}

.bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 3;
    right: 0;
    cursor: pointer;
}

.editContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    .input {
        &:global(.MuiInputBase-root) {
            min-height: 40px;
            height: 40px;
            border-radius: 8px;
            font-size: 11px;

            input {
                text-align: center;
            }
        }
    }

    .icon {
        position: absolute;
        cursor: pointer;
        right: 8px;
        height: 16px;
        width: 16px;

        &:hover {
            fill: $primary-color;
        }
    }
}

.tableContainer {
    width: 100%;

    &.virtualized {
        height: 100%;
    }

    .editModeToolbar {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 20px;
        top: 108px;
        z-index: 3;

        .button {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 8px;
            margin-left: 8px;

            &:hover {
                opacity: 0.8;
            }

            &.saveButton {
                background-color: $succes-color;

                svg {
                    path {
                        fill: $white-color;
                    }
                }
            }

            &.cancelButton {
                background: $default-error-color;

                svg {
                    width: 18px;
                    height: 18px;
                    fill: white;
                }
            }
        }
    }

    .header {
        position: sticky;
        //top: calc($header-height * 2 + 2px);
        top: calc($header-height * 2 + 1px); // 2 is from the border top added
        z-index: 1;

        background-color: $white-color;
    }

    .body {
        background-color: $default-light-background;
        overflow: auto;

        &.virtualized {
            height: calc(100% - $header-height - 20px); // 20 is margin bot of header
            overflow: initial;
        }

        .customFooter {
            position: absolute;
            z-index: 5;
            width: 100%;
            bottom: 8px;
            padding-right: 8px;
        }
    }

    .rowContainer {
        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .row {
        display: flex;
        padding: 0 12px;
        margin: 2px 20px;
        min-height: 48px;
        border-bottom: 1px solid transparent;

        &.headerRow {
            margin: 0px;
            background: $default-light-background;
            padding: 8px 32px;

            margin-bottom: 20px;

            border: 1px solid $primary-border-color;
            border-left: none;
            border-right: none;
        }

        &.white {
            background: $white-color;
            border-radius: 16px;

            &:hover {
                background: $light-border-color;
            }
        }

        &.selected {
            background: $light-border-color;
        }

        .col {
            width: 100%;
            padding: 4px;

            &:first-of-type {
                padding: 4px 0;
            }

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            // in case the cell has some custom elements
            > * {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            align-self: center;

            display: flex;
            align-items: center;

            &.sortable {
                color: $primary-color;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        &.expandedTop {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            margin-bottom: 0;
            border-bottom: 1px solid #d5e3ff;
        }

        &.expandedBot {
            position: relative;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            margin-top: 0;
        }
    }
}

.padding {
    padding-bottom: 8px;
}

.center {
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        cursor: pointer;
    }
}

.icon {
    svg {
        width: 16px;
        height: 16px;
    }
}

.accordionIcon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        fill: $label-text-color;
    }

    &:hover {
        cursor: pointer;
    }
}

.hover {
    &:hover {
        cursor: pointer;
    }
}

.expandableTableRow {
    width: 100%;

    & > div {
        margin: 0 20px;
        padding: 0 27px;
        width: auto;
        background: rgba(230, 238, 254, 0.3);
        border-radius: 16px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    :global(.genericTableHeader) {
        position: initial;
        margin: 0 7px;
        background: transparent;

        :global(.headerRow) {
            margin: 0;
            border: none;
            background: transparent;
            min-height: 24px;
        }
    }

    .rowContainer {
        border-bottom: 1px solid rgba(230, 238, 254, 0.9);
        border-radius: 0 !important;
        padding: 2px 0;

        &:first-of-type {
            border-top: 1px solid rgba(230, 238, 254, 0.9);
        }

        &:last-of-type {
            border-bottom: none;
        }
    }

    :global(.genericTableBody) {
        background-color: transparent;
    }

    :global(.genericTableRow) {
        background: transparent !important;
        margin: 0px 29px;
        min-height: 24px;
        border-radius: 0 !important;
    }
}

@import '../../../assets/style/variables';

.basicCell {
    display: flex;
    align-items: center;

    > a {
        padding: 8px 0;
    }
}

.contactCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    line-height: 32px;

    .avatar {
        font-family: $primary-font-family;
        text-transform: uppercase;
        font-size: 13px;
        height: 32px;
        width: 32px;
        float: left;

        margin: 0 10px 0 0;
    }
}

.hyperlink {
    color: $primary-color;

    > span {
        color: $primary-color;
    }

    &:hover {
        cursor: pointer;
    }
}

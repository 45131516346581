@mixin typography-small1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(18px);
}

@mixin typography-small2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: rem(11px);
    line-height: rem(14px);
}

@mixin typography-small4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(16px);
}

@mixin typography-h6 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: rem(20px);
    line-height: rem(24px);
}

@mixin typography-body1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(20px);
}

@mixin box-shadow-drop1 {
    box-shadow: 0px 16px 100px #e7ebed;
}

@mixin calendar-tablet {
    @media screen and (max-height: 900px), screen and (max-width: $breakpoint-lg) {
        @content;
    }
}

@import '../../assets/style/variables';

.box {
    width: 455px;
    > div {
        width: auto;
        position: relative;
    }
    h6 {
        margin-bottom: 20px;
    }
    .nameRow {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        :global(.MuiAvatar-root) {
            background: #7b61ff;
            margin-right: 12px;
        }
    }

    .line {
        margin: 0 -32px;
        border-color: $default-border-color;
        margin-top: 20px;
    }

    textarea {
        max-height: 250px;
        overflow: auto;
        outline: none;
        overflow-y: scroll !important;
        padding: 16px;
        border-radius: 8px;
        border: 1px dashed $primary-border-color;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        resize: none; /*remove the resize handle on the bottom right*/
        /// font
        //styleName: Mst - Small;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    :global(.MuiInputLabel-root) {
        margin-bottom: 8px;
    }
    :global(.MuiButton-root) {
        min-width: 120px;
        border-radius: 8px;
        min-height: 40x;
        height: 40px;
    }
}

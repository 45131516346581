@import '../../../assets/style/variables';

.editAction {
    box-shadow: 0px 16px 100px rgba(19, 19, 19, 0.06);
    width: 32px;
    height: 32px;
    border-radius: 8px !important;

    &[aria-expanded='true'] {
        border: 1px solid $primary-border-color;
        background: $white-color;
    }
    > svg {
        color: $primary-color;
        min-width: 16px;
    }

    &:hover {
        background: $default-background;
    }

    &.disabled {
        > svg {
            color: rgba(0, 0, 0, 0.26);
        }
    }
}

.rootMenu {
    :global(.MuiPaper-root) {
        margin-top: 8px;
    }

    :global(.MuiMenu-list) {
        padding: 8px 0;
        min-width: 120px;
    }

    :global(.MuiMenuItem-root) {
        color: $primary-color;
    }
}

.link {
    width: 100%;
}

@import '../../../../assets/style/variables';

.header {
    position: sticky;
    z-index: 2;
    border-radius: 8px;
    top: $header-height;
    background-color: $default-light-background;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px 32px;

    min-height: 64px;

    .title {
        display: flex;
        align-items: center;

        color: $primary-text-color;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
    }

    .toolbar {
        display: flex;

        .label {
            display: flex;
            align-items: center;
            margin-right: 8px;
        }

        .selectPeriod {
            margin-right: 8px;
            background-color: $white-color;
        }

        .element {
            display: flex;
            align-items: center;
            border-radius: 8px;
            background-color: $default-background;
            padding: 0 8px;
            margin-right: 8px;

            svg {
                fill: $primary-color;
            }
        }
    }
}

.primary {
    color: $primary-color;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &.nodecoration {
        &:hover {
            text-decoration: none;
            cursor: initial;
        }
    }
}

.historical {
    path {
        fill: $default-avatar-color;
    }
}

@import '../../assets/style/variables';

.accordionWrapper {
    border-radius: 8px !important;
    border: 1px solid $primary-border-color !important;
    padding: 0 20px;
    box-shadow: none !important;
    margin-bottom: 16px;

    &::before {
        background-color: transparent !important;
    }

    .accordionSummary {
        background-color: transparent !important;
        padding: 0;

        .inputError {
            border: 1px solid $default-error-color !important;
        }

        .summary {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .input {
                display: flex;
                justify-content: initial;
                align-items: center;
            }
        }

        > div {
            margin: 0;
        }

        svg {
            fill: $label-text-color;
            height: 18px;

            &.notCompleteSvg {
                fill: $secondary-text-color;
            }
        }

        .title {
            color: $primary-color;
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;

            &.notCompleted {
                color: $secondary-text-color;
            }
        }
    }

    .accordionDetails {
        padding: 0;
        padding-bottom: 20px;

        .selectInput {
            background-color: $white-color;
        }

        label {
            margin-bottom: 4px;
        }

        .footer {
            display: flex;
            justify-content: flex-end;
        }

        .formInput {
            width: 100%;
        }

        .checkboxItem {
            width: 100%;
        }

        .secondary {
            position: relative;
            right: 14px;
        }

        .saveBtnContainer {
            margin-top: 20px;
            display: flex;
            justify-content: center;
        }
    }

    :global(.MuiSelect-outlined) {
        padding-bottom: 8px;
        padding-top: 8px;
    }

    :global(.MuiInputBase-root) {
        min-height: 40px;
        height: 40px;
        border-radius: 8px;
        border-color: $primary-border-color !important;
        input {
            padding: 0 !important;
        }
    }

    .checkboxContainer {
        margin-top: 20px;
        :global(.MuiRadio-root) {
            padding: 0;
            margin-right: 12px;
        }
        :global(.MuiFormControlLabel-root) {
            margin: 0;
            width: 154px;
        }

        :global(.MuiCheckbox-root) {
            padding: 0;
            margin-right: 12px;
        }

        .small {
            width: 100px;
        }
    }

    :global(.MuiRadio-root) {
        height: 20px;
    }
    :global(.MuiCheckbox-root) {
        height: 20px;
    }
}

.footer {
    hr {
        margin: 20px 0 !important;
    }
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 84px;
    :global(.MuiDivider-root) {
        border-color: $primary-border-color;
    }
    :global(.FormModalActions) {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 20px;

        :global(.MuiLoadingButton-root) {
            border-radius: 8px;
            min-height: 40px;
            height: 40px;
        }
    }
}

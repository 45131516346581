@import '../../assets/style/variables';

$pl: 14px;

.background {
    position: fixed;
    height: 100vh;
    z-index: 3;
    width: 100vw;
    left: 0;
    top: 0;
}

.selectPeriodBtn {
    border-radius: 8px !important;
    border-color: $primary-border-color !important;
    color: $primary-text-color !important;
    text-transform: none !important;
    height: 32px;
    display: flex;
    justify-content: space-between !important;
    min-width: 150px !important;

    span {
        margin-right: 8px;
    }

    &:global(.Mui-disabled) {
        border-color: $disabled-color !important;
        span {
            color: $disabled-color;
        }
        svg {
            path {
                fill: $disabled-color;
            }
        }
    }
    &:hover {
        background-color: rgba(55, 69, 242, 0.04) !important;
    }
}
.activeBtn {
    text-decoration: none !important;
    background-color: rgba(55, 69, 242, 0.04) !important;
}

.resetRange {
    padding-left: $pl !important;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: $primary-color;
    &:hover {
        cursor: pointer;
    }
    svg {
        width: 16px;
        height: 16px;
        position: relative;
        top: 4px;
    }
}
.datepicker {
    position: absolute;
    z-index: 3;
    padding: 20px 8px;
    margin-left: 0;

    /* mui datepicker styles */
    opacity: 1;
    transform: none;
    transition: opacity 320ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 213ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 8px;
    box-shadow: 0px 10px 40px rgba(44, 62, 80, 0.25);

    transform-origin: center bottom;

    :global(.rdp-nav_button) {
        height: auto;
        width: auto;
        svg {
            height: 12px;
            width: 12px;
        }
        path {
            fill: $primary-color;
        }
    }
    :global(.rdp-day_selected) {
        &:focus {
            border-color: $label-text-color !important;
            outline: none;
        }
        color: $primary-text-color !important;
        font-weight: 700 !important;
        background-color: $default-background !important;
    }

    :global(.rpd-day_range_end) {
        border-color: $primary-text-color;
    }

    :global(.rdp-day_range_start) {
        border-color: $primary-text-color;
    }

    :global(.rdp-caption_label) {
        font-weight: 700;
        padding-left: $pl;
        font-size: 1rem;
        line-height: 1.25rem;
        font-family: Montserrat, sans-serif;
        color: $primary-text-color;
        border: none;
    }

    :global(.rdp-head_cell) {
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        color: $secondary-text-color;
        text-align: left;
        padding-left: $pl;
    }

    :global(.rdp-tfoot) {
        td {
            padding: 0;
        }
        span {
            padding-left: $pl;
            color: $primary-color;
        }
    }
    :global(.rdp-day) {
        border: 0;
        padding-left: $pl;
        justify-content: flex-start;
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        color: $primary-text-color;
        border-color: transparent;
    }
}

.calendarIcon {
    position: relative;
    top: -1px;
}

$dashboardGap: 20px;

.container {
    width: 100vw;
    display: flex;
    gap: 25px;
    margin: -12px;
    padding: 27px 32px;
}

.left {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: $dashboardGap;
}

.right {
    flex: 0 1 600px;
}

@media only screen and (max-width: 1300px) {
    .container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 860px) {
    .left {
        flex-direction: column;
    }
}

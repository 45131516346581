@import '../../assets/style/variables';

.container {
    min-width: 500px;
    :global(.MuiInput-root),
    :global(.MuiButton-root) {
        border-radius: 8px;
        min-height: 40px;
        height: 40px;
    }

    h6 {
        max-width: 450px;
        margin-bottom: 16px;
    }

    .assetName {
        color: $primary-color;
    }

    form {
        padding: 0;
        padding-top: 16px;
    }

    .divider {
        margin: 0 -32px;
        border-color: $primary-border-color;
    }
}

@import '../../assets/style/variables';

.disabledCard {
    opacity: 0.7;
}

.container {
    display: flex;
    gap: 20px;
    margin: -12px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 28px;
    padding-bottom: 65px;

    @media screen and (max-width: $dashboard-breakpoint-mobile) {
        flex-direction: column;
    }

    .serviceComponentWrapper {
        width: 600px;
        @media screen and (max-width: $dashboard-breakpoint-mobile) {
            width: 100%;
        }
        .countryPicker {
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;
            top: 16px;
            margin-bottom: 12px;

            :global(.MuiInputBase-root) {
                width: 100%;
                margin: 0 10px;
            }
            :global(.MuiSelect-select) {
                padding-bottom: 10px;
                padding-top: 10px;
                display: flex;
                align-items: center;
                background-color: $default-background;
            }

            :global(.MuiFormControlLabel-label) {
                position: relative;
                top: 2px;
            }
        }
        .emptyCard {
            height: 586px;
            @media screen and (max-width: $dashboard-breakpoint-mobile) {
                height: auto;
            }
            box-shadow: 0px 4px 10px 0px #eef5ff;
            background: #fff;
            border-radius: 8px;

            .subTitle {
                display: flex;
                justify-content: center;
                position: relative;
                top: 14px;
            }

            :global(.VictoryContainer) {
                height: auto !important;
            }
            :global(.VictoryContainer):last-child {
                margin-left: 20px;
                margin-top: 10px;
            }
        }
    }

    .card {
        box-shadow: 0px 4px 10px 0px #eef5ff;

        display: flex;
        border-radius: 8px;
        background: #fff;
        padding: 16px;

        @media screen and (min-width: 1100px) and (max-width: 1300px) {
            padding: 12px !important;
        }
    }

    .cardTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 16px;

        > h6 {
            display: flex;
            align-items: center;
        }

        @media screen and (max-width: $dashboard-breakpoint-mobile) {
            > h6 {
                width: 100%;
                margin-bottom: 4px;
            }

            flex-wrap: wrap;
            margin-bottom: 0;
        }
    }

    .even {
        width: 33%;

        @media screen and (min-width: 1100px) and (max-width: 1300px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
        }
    }

    .serviceCard {
        display: block;
        width: 100%;
        height: 105px;
        position: relative;

        @media screen and (min-width: 1100px) and (max-width: 1300px) {
            height: auto;
            display: flex;
            flex-direction: column;
        }

        // for big screens
        @media screen and (min-width: $breakpoint-xl) {
            display: flex;
            flex-direction: row;

            > div:first-child {
                max-width: 250px;
            }
        }

        margin-bottom: 8px;
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 8px;

        .body {
            display: flex;
            margin-top: 16px;

            @media screen and (min-width: $breakpoint-xl) {
                margin-top: 0;
            }

            @media screen and (min-width: 1100px) and (max-width: 1300px) {
                flex-direction: column;
            }
        }

        > div {
            width: 100%;
            @media screen and (min-width: $breakpoint-xl) {
                width: auto;
                flex-grow: 1;
                align-items: center;
            }
        }

        .title {
            color: #3745f2;
            &.disabled {
                color: #979797;
            }
        }
    }

    .actionBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        border: 1px solid #d5e3ff;
        background: #fff;
        cursor: pointer;
        &:hover {
            background-color: $primary-hover-color;
        }
    }
}

.disabledCard {
    color: #979797 !important;
    border-color: #979797;
    svg {
        fill: #979797 !important;
        path {
            fill: #979797 !important;
        }
    }
}

.insightBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #d5e3ff;
    background: #fff;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.float {
        right: 12px;
        top: 12px;
        position: absolute;
    }

    &:hover {
        background-color: #6a9bff;
    }
}

.infrastructureContainer {
    width: calc(50% - 10px); //half of gap;

    @media screen and (max-width: $dashboard-breakpoint-mobile) {
        width: 100%;
    }

    .column {
        display: flex;
        flex-direction: column;
    }

    .bigRow {
        width: 100%;
        display: flex;
        height: 60px;
        gap: 10px;
        align-items: center;

        color: #3745f2;

        > span {
            width: 50%;
            display: flex;
        }
    }

    .row {
        display: flex;
        gap: 10px;

        .element {
            width: 50%;
            display: flex;
            flex-direction: column;
            padding: 16px;

            > span {
                display: flex;
                align-items: center;
                color: #3745f2;
            }
        }
    }
}

.assetsContainer {
    width: calc(50% - 10px); //half of gap;

    @media screen and (max-width: $dashboard-breakpoint-mobile) {
        width: 100%;
    }

    .chartContainer {
        width: 100%;
        height: 200px;
    }

    .assetRow {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        margin-top: 16px;
        @media screen and (max-width: $dashboard-breakpoint-mobile) {
            margin-top: 8px;
        }

        .assetCard {
            width: calc(50% - 5px);
            display: flex;
            flex-direction: column;
            padding: 16px;
            min-height: 130px;

            &.noMinHeight {
                min-height: initial;
            }

            .content {
                display: flex;
                gap: 16px;

                @media screen and (max-width: $dashboard-breakpoint-mobile) {
                    gap: 8px;
                }

                span {
                    &:first-of-type {
                        width: calc(55% - 8px);
                    }

                    &:last-of-type {
                        width: calc(45% - 8px);
                    }
                }
            }

            > span {
                display: flex;
                align-items: center;
                color: #3745f2;
            }
        }
    }
}

.systemStatusContainer {
    width: calc(50% - 10px); //half of gap;

    @media screen and (max-width: $dashboard-breakpoint-mobile) {
        width: 100%;
    }

    .card {
        flex-direction: column;
        height: 208px;
    }

    .body {
        display: flex;

        > div {
            width: 33%;
        }
    }
}
.connectedContainer {
    width: calc(50% - 10px); //half of gap;

    @media screen and (max-width: $dashboard-breakpoint-mobile) {
        width: 100%;
    }

    .card {
        flex-direction: column;
        height: 208px;
        .body {
            display: flex;
            > div {
                flex-grow: 1;
            }
        }
    }
}

.PerformanceComponent {
    width: 100%;
    .accuracyCard {
        width: 265px;
        @media screen and (min-width: 1100px) and (max-width: 1300px) {
            width: 220px;
        }

        @media screen and (max-width: $dashboard-breakpoint-mobile) {
            width: calc(50% - 2px);
        }
        height: 100%;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;

        .element {
            div:first-child {
                margin-top: 12px;
            }

            .bar {
                width: 5px;
                height: 21px;
                border-radius: 16px;
                background: #3745f2;
            }

            .title {
                color: #828282;
            }
        }

        //height: 157px;
    }

    .performanceRow {
        height: 76px;
        width: 100%;
        padding: 0 16px;
        @media screen and (max-width: $dashboard-breakpoint-mobile) {
            width: 100%;
            flex-wrap: wrap;
            height: auto;
        }
    }

    .performanceRowColumn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 20%;
        @media screen and (max-width: $dashboard-breakpoint-mobile) {
            width: 25%;
            flex-wrap: wrap;
            margin-bottom: 8px;
        }

        :global(.MuiIconButton-root) {
            position: relative;
            top: 4px;
        }

        .subtitle {
            margin-top: 4px;
            color: $secondary-text-color;
        }

        &.title {
            width: 220px;
            @media screen and (max-width: $dashboard-breakpoint-mobile) {
                width: 100%;
                margin-top: 8px;
                margin-bottom: 4px;
                display: flex;
                align-items: center;

                .titleLabel {
                    width: 170px;
                }
            }
            svg {
                margin-right: 10px;
            }
        }
    }

    .body {
        display: flex;
        gap: 4px;

        @media screen and (max-width: $dashboard-breakpoint-mobile) {
            flex-direction: column;
            > div:first-child {
                flex-direction: row;
            }
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

.cardSubtitle {
    width: 100%;
    display: flex;
    align-items: baseline;
    margin-bottom: 20px !important;
    span {
        margin-left: 8px;
    }
}

.dot {
    width: 8px;
    height: 8px;
    background-color: #6fd8b2;
    border-radius: 50%;

    &.red {
        background-color: #e55151;
    }
}

.wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
}

@import '../../../assets//style//variables';

.filterInput {
    width: 132px;
    height: 32px;
    background: $white-color;

    :global(.MuiOutlinedInput-root) {
        height: 100%;

        border-color: $primary-border-color !important;
        border-radius: 8px;

        > input {
            font-size: rem(12px) !important;
            color: $primary-text-color !important;
            padding: 3.5px 14px !important;
        }
    }
}

.container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 #eef5ff;
    display: flex;
    padding: 16px;

    &.disabled {
        opacity: 0.7;
        filter: grayscale(1);
    }

    &.row {
        width: 100%;
        min-height: 130px;
        flex-direction: column;
        gap: 8px;

        &.noMinHeight {
            min-height: auto;
        }

        .header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            color: #3745f2;

            svg {
                width: 18px;
                height: 18px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;

            .info {
                width: 100%;
                display: flex;
                gap: 8px;

                .title {
                    width: calc(55% - 8px);
                }
            }
        }

        @media only screen and (max-width: 860px) {
            min-height: auto;
        }
    }

    &.column {
        width: 100%;
        min-height: 76px;

        .header {
            width: 220px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            svg {
                width: 23px;
                height: 23px;
            }
        }

        .content {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            gap: 4px;
            flex: 1 0;

            .info {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: flex-start;
                gap: 4px;
                min-width: 125px;
            }
        }

        @media only screen and (max-width: 860px) {
            flex-direction: column;
            gap: 8px;

            .content {
                justify-content: space-between;

                .info {
                    min-width: auto;
                }
            }
        }
    }
}

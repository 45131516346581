@import '../../assets/style/variables';

.loading {
    height: 100%;
    width: 100%;
}

.pageContainer {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $primary-border-color;
    background-color: #fbfcff;
    display: flex;
    flex-direction: column;
    position: relative;
}

.flex {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.downloadBtn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white-color;
    border: 1px solid $primary-border-color;
    box-shadow: 0px 4px 10px $light-border-color;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 8px;

    svg {
        fill: $primary-color;
        font-size: 18px;
    }

    &:hover {
        background-color: $light-border-color;
    }
}

.subHeaderContainer {
    display: flex;
    border-top: 1px solid $primary-border-color;
    border-bottom: 2px solid $primary-border-color;
    justify-content: space-between;

    .subHeaderElement {
        height: 40px;
        width: 100%;
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-text-color;

        span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .black {
            color: $primary-text-color;
        }

        .location {
            color: $primary-color;
            cursor: pointer;
            display: flex;
            align-items: center;

            svg {
                path {
                    fill: $primary-color;
                }
            }

            > span {
                max-width: 100px;
            }
        }

        &.border {
            border-right: 1px solid $primary-border-color;
        }

        .primary {
            color: $primary-color;
        }

        &.margin {
            padding: 0 8px;
        }
    }
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    color: $primary-color;
    padding: 12px;
    padding-left: 40px;
    padding-right: 20px;

    .icon {
        > path {
            fill: $primary-color;
        }
    }

    .header {
        display: flex;
        align-items: center;
        color: $primary-text-color;
        height: 32px;

        .title {
            margin-left: 12px;
        }

        svg {
            width: 24px;
            height: 24px;
        }

        .subtitle {
            display: flex;
            align-items: center;
            position: relative;
            top: 1px;
        }
    }
    .black {
        svg {
            path {
                fill: $primary-text-color;
            }
        }
    }
    .primary {
        svg {
            path {
                fill: $default-avatar-color;
            }
        }
    }
    .toolbarContainer {
        display: flex;

        .customBarStyle {
            background-color: $primary-color !important;
            svg {
                fill: $white-color;
            }
        }

        .button {
            text-transform: none;
            min-width: 32px;
            width: auto;
            padding: 0 8px;
            &:hover {
                background-color: $light-border-color !important;
            }
        }

        .actionsWrapper {
            height: 32px;
            width: 32px;
            border: 1px solid $primary-border-color;

            :global(.MuiButtonBase-root) {
                background-color: $white-color;
                text-transform: none;

                border-radius: 8px;
                border: 1px solid $primary-border-color;

                &:hover {
                    background-color: $light-border-color !important;
                }
            }
        }
        .ingestButton {
            width: auto;
            padding: 0 6px;
        }
    }
}

.align {
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
    }
}

.green {
    color: #6fd8b2;
}

.red {
    color: #e55151;
}

.dot {
    width: 5px;
    height: 5px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: $primary-text-color;
    color: $white-color;
}

.mr1 {
    margin-right: 8px !important;
}
.ml1 {
    margin-left: 8px !important;
}
.m0 {
    margin: 0 !important;
    position: relative;
    top: 1px;
}

.actionsContainer {
    position: absolute;
    top: 13px;
    right: 16px;
}

@import '../../assets/style/variables';

.listRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .listIndex {
        min-width: 20px;
        text-align: end;
        margin-right: 8px;
    }
    .permissionSelect {
        :global(.MuiSelect-select) {
            display: flex;
            align-items: center;
        }
        &.isDropdownOpen {
            //border-radius: 8px !important;
        }
        height: 30px;
        border-radius: 16px;
        position: relative;
        right: 1px;
    }
    .icon {
        margin-left: 8px;
        cursor: pointer;
        position: relative;
        height: 30px;
        width: 30px;
        top: 4px;
    }
}
.formContainer {
    .title {
        margin-bottom: 20px;
    }

    .selectedUsersContainer {
        margin: 20px 0;
        position: relative;
        max-height: 300px;
        overflow-y: scroll;
        min-height: 300px;
        .footerNote {
            position: absolute;
        }
        :global(.MuiInputBase-root),
        :global(.MuiButton-root) {
            min-height: 30px;
            height: 30px;
            border-radius: 16px;
        }
    }

    hr {
        border-color: $primary-border-color;
        margin: 0 -32px;
    }

    :global(.MuiInputBase-root),
    :global(.MuiButton-root) {
        min-height: 40px;
        height: 40px;
        border-radius: 8px;
        input {
            padding: 0 !important;
        }
    }
}
.optionSvg {
    margin-right: 8px;
}

.dropdownListRow {
    display: flex;
    justify-content: space-between;
    margin: 16px;
    cursor: pointer;
    &:hover {
        .rowElement {
            font-weight: bold;
        }
    }
    .highlight {
        background: $light-border-color;
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 4px;
    }
    .rowElement {
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 190px;
        font-size: 12px;
        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

:global(.PermissionFooterClassName) {
    :global(.MuiTypography-formFooterNote) {
        color: black;
    }
    :global(.MuiLoadingButton-root) {
        width: 134px;
        position: relative;
        right: 1px;
    }
}

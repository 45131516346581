@import '../../../assets/style/variables';

.tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    z-index: 2;
    border-radius: 8px;
    top: $header-height;
    background-color: $default-light-background;
    min-height: 42px;

    padding: 16px 32px;
    .title {
        color: $primary-text-color;
    }

    .actions {
        display: flex;
        align-items: center;
    }
}

@import '../../assets/style/variables';

.wrapper {
    // overflow: hidden;

    position: relative;
    // 8px = scrollbar width
    max-width: calc(100vw - 8px);
    height: 100vh;
    background-color: #f5f9ff;

    .contentWrapper {
        display: flex;
        position: relative;
        box-shadow: 0px -5px 10px $primary-border-color;
        border-radius: 16px;
        top: $header-height;
        min-height: calc(100% - $header-height);
        z-index: 0;

        .content {
            transition: 300ms all ease-in-out;
            width: 100%;
            padding: 12px;
            background-color: #f5f9ff;
        }
    }

    .badgeWrapper {
        position: fixed;
        right: 20px;
        bottom: 18px;

        .badgeStatus {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: $default-badge-color;
            position: absolute;
            right: 6px;
            top: 4px;
        }
    }
}

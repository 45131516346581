.button {
    border-radius: 8px !important;
    border-width: 1px !important;
    border-style: solid !important;
    padding: 2px 4px !important;
    margin: 0 4px !important;

    font-weight: 400 !important;
    font-size: 0.75rem !important; // 12px
    line-height: 1rem !important;
    min-width: initial !important;
}

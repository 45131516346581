:root {
    --toastify-font-family: $primary-font-family !important;
    --toastify-toast-width: 400px !important;
    --toastify-color-success: $default-dark-color;
    --toastify-color-warning: $default-dark-color;
    --toastify-color-error: $default-dark-color;
    --toastify-toast-min-height: 40px !important;
}

.Toastify__toast {
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 16px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    overflow: initial !important;

    div:not(.Toastify__toast-body):not(.Toastify__toast-icon):not(.toastSquare) {
        padding: 16px 8px 16px 16px;
        border-radius: 8px;
        box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    }
}

.toastIcon {
    border-radius: 50%;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
}

.toastSquare {
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    position: fixed;
    left: $notification-icon-space;
    z-index: -1;
    top: 20px;
}

.Toastify__toast-body {
    padding: 0 !important;
    position: relative;
    margin: 0 !important;
    @include typography-small1;
}

.Toastify__toast-icon {
    width: auto !important;
}

.Toastify__toast--success {
    div:not(.Toastify__toast-body):not(.Toastify__toast-icon) {
        background-color: $light-border-color !important;
    }

    .toastSquare {
        background-color: $light-border-color !important;
    }
}

.Toastify__toast--info {
    div:not(.Toastify__toast-body):not(.Toastify__toast-icon) {
        background: rgb(252 234 177) !important;
    }

    .toastSquare {
        background-color: rgb(252 234 177) !important;
    }
}

.Toastify__toast--error {
    div:not(.Toastify__toast-body):not(.Toastify__toast-icon) {
        background: rgb(242 199 202) !important;
    }

    .toastSquare {
        background-color: rgb(242 199 202) !important;
    }
}

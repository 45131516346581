.container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;

    // style[DashboardCardType]
    &.row {
        @import '../../DashboardV2.module.scss';
        width: calc(50% - $dashboardGap / 2);

        @media only screen and (max-width: 860px) {
            width: 100%;
        }
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .stats {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 25px;

            .stat {
                display: flex;
                gap: 8px;
            }
        }
    }

    .content {
        width: 100%;
        gap: 5px;
        display: flex;
        flex-direction: column;

        &.loading {
            height: 100%;
            min-height: 200px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 4px 10px 0 #eef5ff;
        }

        // style[DashboardCardType]
        &.row {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(calc(50% - 5px), 1fr));
            gap: 10px;
        }
    }
}

.loader {
    position: absolute;
}

@import '../../assets/style/variables';

.active {
    color: $primary-color;
    &:hover {
        cursor: pointer;
    }
}

.editActionStyling {
    svg {
        color: $label-text-color;
    }
}

.dot {
    height: 4px;
    width: 4px;
    background-color: black;
    border-radius: 50%;
    margin: 0 8px;
}

.detailsContainer {
    display: flex;
    align-items: center;
}

.downloadWrapper {
    color: $primary-color;
    margin-left: 16px !important;

    svg {
        height: 12px;
        width: 12px;
        margin-right: 10px;
    }

    path {
        fill: $primary-color;
    }

    &:hover {
        color: $primary-hover-color;
        cursor: pointer;
    }
}

.link {
    margin: 0 6px;
    color: $primary-color;
    text-decoration: underline;
    //@include typography-h6;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: rem(20px);
    line-height: rem(24px);
}

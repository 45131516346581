@import '../../assets/style/variables';

.container {
    hr {
        margin: 20px -32px;
        border-color: $default-border-color;
    }

    .item {
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &.small {
            width: 200px;
        }
    }

    .secondaryTitle {
        margin-top: 56px;
        margin-bottom: 40px;
    }

    .phoneNumberInput {
        width: 100%;
    }
    :global(.MuiAutocomplete-endAdornment) {
        top: 0;
    }
    :global(.MuiInputBase-root),
    :global(.MuiButtonBase-root) {
        min-height: 40px;
        height: 40px;
        border-radius: 8px;
        input {
            padding: 0 !important;
        }
    }
}

.languageSelectorDropdown {
    max-height: 240px;

    :global(.MuiPaper-root) {
        margin: 0 0 0 -13px;
        width: 410px;
    }

    :global(.MuiPhoneNumber-flagIcon) {
        border-radius: 6px;
        width: 30px;
    }
}

.dropdownStyle {
    border-color: #d4e1fd !important;
    max-height: 350px !important;

    :global(.MuiMenu-list .MuiMenuItem-root) {
        max-width: 320px;
    }
}

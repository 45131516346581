@import '../../../assets/style/variables';

.tableFooter {
    display: inline-block;
    background: $default-light-background;
    width: 100%;

    border-top: none;

    img {
        display: block;
        margin: 0 auto;
        width: 24px;

        &.loading {
            animation-name: spin;
            animation-duration: 5000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }

    .disablePointer {
        cursor: initial;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.disabled {
    filter: invert(65%) sepia(0%) saturate(0%) hue-rotate(331deg) brightness(93%) contrast(91%);
}

.loadMore {
    :global(&.Mui-disabled) {
        background: transparent;
    }
}

@import '../../assets/style/variables';

.headerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    z-index: 2;
    border-radius: 8px;
    top: $header-height;
    background-color: $default-light-background;

    padding: 16px 32px;

    .selectedLabel {
        margin-right: 8px;
    }

    .title {
        color: $primary-text-color;
    }

    .button {
        padding-left: 0;
        margin-right: 8px;
        min-width: 80px;
        svg {
            font-size: 16px;
            fill: $primary-color;
        }
    }
}

.autogenerated {
    color: $default-error-color !important;

    > * {
        color: $default-error-color !important;
    }
}

.editActionStyling {
    svg {
        color: $label-text-color;
    }
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-Regular.woff2') format('woff2'), url('Matter-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-Medium.woff2') format('woff2'), url('Matter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'MatterSQ';
    src: url('MatterSQ-Regular.woff2') format('woff2'), url('MatterSQ-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MatterSQ';
    src: url('MatterSQ-Medium.woff2') format('woff2'), url('MatterSQ-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.container {
    min-width: 500px;
    :global(.MuiInput-root),
    :global(.MuiButton-root) {
        border-radius: 8px;
        min-height: 40px;
        height: 40px;
    }

    h6 {
        max-width: 450px;
        margin-bottom: 16px;
    }

    .assetName {
        color: $primary-color;
    }

    form {
        padding: 0;
        padding-top: 16px;
    }

    .divider {
        margin: 0 -32px;
        border-color: $primary-border-color;
    }

    .formRow {
        margin-bottom: 20px;

        :global(.MuiInputLabel-root) {
            margin-bottom: 8px;
        }

        :global(.MuiAutocomplete-inputRoot) {
            width: 100%;
            padding: 0 6px;
        }

        :global(.MuiInputBase-root) {
            border-radius: 8px;
            border-color: $primary-border-color;
            font-size: 14px;
            line-height: 18px;
            min-height: 40px;
            height: 40px;
            &:hover {
                background-color: rgba(55, 69, 242, 0.04);
            }
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #888888;
            opacity: 1; /* Firefox */
        }
    }
}

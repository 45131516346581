@import '../../assets/style/variables';

.pageContainerWrapper {
    max-width: 85vw;
    margin: 0 auto;

    @media (max-width: 1000px) {
        width: 90vw;
    }
}

.header {
    border-bottom: 1px solid $primary-border-color;
    .pageTitle {
        height: 60px;
        color: $primary-text-color;
        padding-left: 40px;
        display: flex;
        align-items: center;
    }
}

.assetInput {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 16px;
    margin-bottom: 20px;
    .wrapper {
        border: 1px solid $primary-border-color;
        padding: 10px;
        background-color: $white-color;
        border-radius: 8px;

        :global(.MuiOutlinedInput-root) {
            min-height: 44px;
            height: 44px;
            padding-top: 0;
            padding-bottom: 0;
            background: $light-border-color;
            border-radius: 8px;

            :global(.MuiOutlinedInput-input) {
                color: $primary-color;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}
.container {
    border-radius: 8px;
    border: 1px solid $primary-border-color;
    background: $white-color;
    min-height: 100px;
    margin: 20px 40px 20px;

    :global(.MuiTabPanel-root) {
        padding: 32px;
    }
    .tabsHeader {
        border-bottom: 1px solid $primary-border-color;
        svg {
            font-size: 24px;
        }
        :global(.MuiTabs-fixed) {
            > div {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 24px 48px;
                padding-bottom: 0;
            }

            :global(.MuiButtonBase-root) {
                color: $label-text-color;
                border-radius: 0;
                position: relative;
                svg {
                    margin-bottom: 24px;
                }
                path {
                    fill: $label-text-color;
                }
            }

            :global(.Mui-selected) {
                background: $white-color;
                padding: 0 8px;
                color: $primary-color;
                border-radius: 0;
                :global(.underline) {
                    display: block;
                }
                path {
                    fill: $primary-color;
                }
            }
        }
        .tabLabel {
            margin-bottom: 24px;
            .underline {
                display: none;
                height: 8px;
                border-radius: 12px 12px 0px 0px;
                background: $primary-border-color;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
            }
        }
    }
}

@import '../../../../assets/style/variables';

.serviceComponentWrapper {
    width: 100%;

    @media screen and (max-width: $dashboard-breakpoint-mobile) {
        width: 100%;
    }

    .countryPicker {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        top: 16px;
        margin-bottom: 12px;

        :global(.MuiInputBase-root) {
            width: 100%;
            margin: 0 10px;
        }

        :global(.MuiSelect-select) {
            padding-bottom: 10px;
            padding-top: 10px;
            display: flex;
            align-items: center;
            background-color: $default-background;
        }

        :global(.MuiFormControlLabel-label) {
            position: relative;
            top: 2px;
        }
    }

    .emptyCard {
        @media screen and (max-width: $dashboard-breakpoint-mobile) {
            height: auto;
        }

        box-shadow: 0px 4px 10px 0px #eef5ff;
        background: #fff;
        border-radius: 8px;

        .subTitle {
            display: flex;
            justify-content: center;
            position: relative;
            top: 14px;
        }

        :global(.VictoryContainer) {
            height: auto !important;
        }

        :global(.VictoryContainer):last-child {
            margin-left: 20px;
            margin-top: 10px;
        }
    }
}

.card {
    box-shadow: 0px 4px 10px 0px #eef5ff;

    display: flex;
    border-radius: 8px;
    background: #fff;
    padding: 16px;

    @media screen and (min-width: 1100px) and (max-width: 1300px) {
        padding: 12px !important;
    }
}

.cardTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 16px;

    > h6 {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: $dashboard-breakpoint-mobile) {
        > h6 {
            width: 100%;
            margin-bottom: 4px;
        }

        flex-wrap: wrap;
        margin-bottom: 0;
    }
}

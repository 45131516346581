@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.loading {
    width: auto;
    height: auto;
}
.box {
    width: 455px;

    > div {
        width: auto;
    }

    h6 {
        margin-bottom: 20px;
    }

    .formRow {
        margin-bottom: 20px;

        .dateRange {
            button > span {
                color: $dark-grey;

                @include typography-small1;
            }

            :global(.MuiButton-root) {
                width: 100%;
                min-height: 40px;
                height: 40px;
            }
            :global(.rdp) {
                position: fixed;
            }
        }

        .black {
            color: $dark-grey;
        }

        :global(.MuiInputLabel-root) {
            margin-bottom: 8px;
        }

        .autocompleteOpen {
            background-color: rgba(55, 69, 242, 0.04);
        }
        :global(.MuiOutlinedInput-root) {
            width: 220px;
        }

        :global(.MuiInputBase-root) {
            border-radius: 8px;
            border-color: $primary-border-color;
            font-size: 14px;
            line-height: 18px;
            min-height: 40px;
            height: 40px;
            &:hover {
                background-color: rgba(55, 69, 242, 0.04);
            }
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #888888;
            opacity: 1; /* Firefox */
        }
    }

    .flexBetween {
        display: flex;
        justify-content: space-between;
        :global(.MuiInputBase-adornedEnd) {
            padding: 0;
        }
        :global(.MuiInputAdornment-root) {
            display: none;
        }
    }

    hr {
        margin: 0 -32px;
        border-color: $default-border-color;
    }

    .fullWidth {
        > div {
            width: 100% !important; //overwrite the outline style
            :global(.MuiAutocomplete-inputRoot) {
                width: 100%;
                padding: 0 6px;
            }
        }
    }

    :global(.MuiLoadingButton-root) {
        border-radius: 8px;
        min-height: 40px;
        height: 40px;
    }
}

.selectRow {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    &:hover {
        background-color: $primary-hover-color;
        color: $white-color;
        cursor: pointer;
    }
    svg {
        margin-right: 8px;
    }
}

.dataLoading {
    width: 100%;
    height: 100%;
    position: absolute;
}

@import '../../assets/style/variables';

.noValueContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: $label-text-color;

    svg {
        width: 160px;
        height: 128px;
        margin-bottom: 24px;
    }
}

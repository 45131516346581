@import '../../assets/style/variables';

.box {
    width: 455px;
    > div {
        width: auto;
        position: relative;
    }
    .title {
        margin-bottom: 24px;
    }

    .label {
        margin-bottom: 20px;
    }

    .itemBox {
        border-radius: 8px;
        border: 1px dashed $primary-border-color;
        margin-bottom: 20px;

        color: $primary-color;
        background: $white-color;

        padding: 7px 20px;

        display: flex;
        align-items: center;

        > svg {
            margin-right: 20px;
        }
    }

    .line {
        margin: 0 -32px;
        border-color: $default-border-color;
        margin-top: 20px;
    }

    :global(.MuiInputLabel-root) {
        margin-bottom: 8px;
    }
    :global(.MuiButton-root),
    :global(.MuiOutlinedInput-root) {
        min-width: 120px;
        border-radius: 8px;
        min-height: 40x;
        height: 40px;
        &:global(.MuiAutocomplete-inputRoot) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

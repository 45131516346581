@import '../../assets/style/variables';

.modalContainer {
    // overwrite default padding, its very big and looks weird
    padding: 24px !important;
}
.box {
    width: 260px;
    .title {
        margin-bottom: 20px;
    }
    .label {
        margin-bottom: 120px;
    }
    .deleteIcon {
        width: 48px;
        height: 48px;
        background: $primary-color;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        svg {
            font-size: 24px;
            fill: $white-color;
        }
    }
    :global(.MuiButton-text) {
        width: auto !important;
        padding: 0;
        margin-right: 20px;
    }
    :global(.MuiButton-root) {
        border-radius: 8px;
        height: 40px;
        min-height: 40px;
        width: 120px;
    }
}

@import '../../assets/style/variables';

.dropdownRow {
    margin: 2px 8px;
    padding: 4px 8px;
    border-radius: 8px;
    &:global(.Mui-focused) {
        background: $default-background;
        cursor: pointer;
    }
    &[aria-selected='true'] {
        background: $default-background;
    }
}
.container {
    hr {
        margin: 20px -32px;
        border-color: $default-border-color;
    }
    .item {
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .phoneNumberInput {
        width: 100%;
    }

    :global(.MuiAutocomplete-endAdornment) {
        top: 0;
    }
    :global(.MuiInputBase-root),
    :global(.MuiButtonBase-root) {
        min-height: 40px;
        height: 40px;
        border-radius: 8px;
        border-color: $primary-border-color;
        input {
            padding: 0 !important;
        }
    }
}

.dropdownStyle {
    border-color: #d4e1fd !important;
    max-height: 350px !important;

    :global(.MuiMenu-list .MuiMenuItem-root) {
        max-width: 320px;
    }
}

.disabled {
    filter: invert(65%) sepia(0%) saturate(0%) hue-rotate(331deg) brightness(93%) contrast(91%);
}

.checkboxRow {
    display: flex;
    div:last-child {
        margin-left: 16px;
    }
}

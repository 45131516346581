@import '../../../assets/style/variables';

.authWrapper {
    font-family: 'Matter';
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 8px 8px 0 8px;

    .resetPassword {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;
        font-family: $primary-font-family;
        margin: auto;
        width: 100%;

        .illustration {
            width: 45%;

            img {
                width: 100%;
                border-radius: 24px;
                height: calc(100vh - 16px);
            }
        }

        .content {
            width: 55%;
            height: calc(100vh - 16px);
            border-radius: 24px;
            background-color: #f1f1f2;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .logoContainer {
                margin-bottom: 56px;

                .logo {
                    width: 116px;
                }
            }

            .container {
                border: 1px solid transparent;
                border-radius: 20px;

                .title {
                    font-family: 'Matter';
                    color: #171d3b;
                    font-size: 48px;
                    font-weight: 400;
                    margin: 0 auto 72px auto;
                    width: 592px;
                    text-align: center;
                }

                .label {
                    color: $primary-text-color;
                    line-height: 1rem;
                }
            }

            .options {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                margin: 24px 0 0;
                width: 100%;

                span {
                    font-family: $primary-font-family;
                    font-style: normal;
                    line-height: 27px;
                    font-weight: 700;
                    font-size: 13px;
                    color: $primary-color;
                }
            }

            :global(.MuiInput-root) {
                border-color: #ffffff;
            }

            :global(.MuiInput-root),
            :global(.MuiButton-root) {
                height: 64px;
                min-height: 64px;
                border-radius: 64px;
                padding: 0 24px;
            }
        }
    }
}

.primary {
    font-family: 'Matter' !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #3d30fb !important;
}

@media screen and (max-width: 1120px) {
    .authWrapper {
        .resetPassword {
            flex-direction: column-reverse;
            align-items: center;

            .content {
                max-width: 100%;
                width: 100% !important;

                .options {
                    gap: 12px;
                    flex-direction: column-reverse;

                    button {
                        margin: 0;
                        min-width: 200px;
                    }
                }

                .container {
                    padding: 24px 12px;
                    width: 100%;
                    max-width: 592px;

                    .title {
                        width: 100% !important;
                        font-size: 34px !important;
                        line-height: 28px;
                        margin: 0 auto 48px auto;
                    }

                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .illustration {
        display: none;
    }
}

@import '../../assets/style/variables';

.alignCenter {
    display: flex;
    align-items: center;
}

.hr {
    border-color: $white-color;
    opacity: 0.2;
}

.chartHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.wrapper {
    flex-grow: 2;
    display: flex;
    flex-direction: column;

    .genericHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .toolbarContainer {
            position: relative;

            display: flex;
            align-items: center;

            padding: 8px 20px;

            .dateRange {
                background: $white-color;

                :global(.MuiButton-root) {
                    justify-content: space-between;
                    width: 224px;
                    height: 32px;
                    color: $primary-text-color !important;
                }

                :global(.MuiTypography-small1) {
                    // overwrite small1 font
                    font-size: 14px;
                }
            }

            .timeSelect {
                margin-left: 8px;
                width: 158px;
                svg {
                    color: $label-text-color;
                }
                :global(.MuiOutlinedInput-root) {
                    height: 32px;
                    border-radius: 8px;
                    background: $white-color;
                    > div {
                        padding: 4px;
                        display: flex;
                        align-items: center;
                        padding-left: 12px;
                    }
                    input {
                        height: 100%;
                    }
                }
            }

            .timeError {
                :global(.MuiOutlinedInput-root) {
                    border-color: $default-error-color !important;
                }
            }

            .timePicker {
                width: 60px;
                margin-left: 8px;

                :global(.MuiButtonBase-root) {
                    padding: 0;
                    padding-right: 4px;
                }
                :global(.MuiInputAdornment-root) {
                    display: none;
                    /*position: relative;
                    left: -8px;*/
                }
                :global(.MuiOutlinedInput-input) {
                    padding: 8px;
                    text-align: center;
                    font-size: 12px;
                    line-height: 16px;
                }
                :global(.MuiOutlinedInput-root) {
                    height: 32px;
                    padding-right: 0;
                    border-radius: 8px;
                    border-color: $primary-border-color;
                    background: $white-color;
                }
            }
        }

        .label {
            color: $primary-color;
            height: 32px;
            // overwrite the default styling because small1 is 14px and we have it at 12px.
            font-size: 14px;
            svg {
                color: $primary-color;
                width: 24px;
                height: 24px;
                margin-left: 8px;
            }
        }

        .cta {
            cursor: pointer;

            &:hover {
                color: $primary-hover-color;
                svg {
                    color: $primary-hover-color;
                }
            }
        }

        .alignCenter {
            display: flex;
            align-items: center;
        }
    }

    .generationTableContainer {
        flex-grow: 2;
        padding-bottom: 8px;

        .dataLoading {
            width: 100%;
            height: 100%;
        }

        :global(.tableFooterContainer) {
            border-radius: 0;
            border-left: 0;
            border-right: 0;
            display: none;
        }

        .generationTable {
            /* overwrite basic styling from generic table */
            :global(.tableHeaderCell) {
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
            }

            :global(.genericTableBody) {
                overflow: auto;
                max-height: calc(100vh - 374px);
                border-right: 0;
                border-left: 0;
            }

            &.smallTable {
                :global(.genericTableHeader) {
                    > div {
                        border-top: 0;
                    }
                }
                :global(.genericTableBody) {
                    max-height: calc(100vh - 324px);
                }
            }

            &.bigTable {
                :global(.genericTableHeader) {
                    > div {
                        border-top: 0;
                    }
                }
                :global(.genericTableBody) {
                    max-height: calc(100vh - 282px);
                }
            }

            :global(.genericTableHeader) {
                & > div {
                    border-radius: 0;
                    border-left: 0;
                    border-right: 0;
                }
                // so scroll can work on the table
                top: 0;
            }
        }

        &.gas {
            :global(.tableFooterContainer) {
                display: block;
            }

            :global(.genericTableBody) {
                max-height: calc(100vh - 284px);
            }
        }
    }
}

@import './assets/style/variables';

.pageContainer {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $primary-border-color;
    background-color: #fbfcff;
    display: flex;
    flex-direction: column;
    position: relative;
}

.subHeaderContainer {
    display: flex;
    border-top: 1px solid $primary-border-color;
    border-bottom: 1px solid $primary-border-color;

    .subHeaderElement {
        flex-grow: 1;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-text-color;
        padding: 0 24px;
        white-space: nowrap;

        .ml {
            margin-left: 8px;
        }

        .mr {
            margin-right: 8px;
        }

        .assetTypeBox {
            margin-left: 12px;

            display: flex;
            align-items: center;
        }

        .black {
            color: $primary-text-color;
        }

        &.border {
            border-right: 1px solid $primary-border-color;
        }

        .primary {
            color: $primary-color;
        }

        :global(.MuiButton-root) {
            display: flex;
            justify-content: flex-start;
            min-width: auto;
        }
    }

    .subHeaderElement:last-child {
        border-right: 0;
    }
}

.center {
    > div {
        display: flex;
    }
}

.spinnerContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .dataLoading {
        width: 100%;
        height: 100%;
    }
}

.borderBottom {
    border-bottom-width: 1px;
}

.locationSVG {
    margin-right: 4px;
    height: 16px;
    width: 16px;

    path {
        fill: $primary-color;
    }
}

.link {
    color: $primary-color;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: $primary-hover-color;

        path {
            fill: $primary-hover-color;
        }
    }
}

.warningIcon {
    display: flex;
    align-items: center;

    svg {
        position: relative;
        top: -1px;
        height: 16px;
        width: 16px;
        margin-right: 4px;
    }
}

.locationText {
    position: relative;
    top: -4px;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;

    svg {
        position: relative;
        top: 4px;
    }
}

.hover {
    cursor: pointer;
}

.fileInput {
    &:global(.MuiInputBase-root) {
        border-radius: 8px;
        min-height: 40px;
        min-width: 400px;
    }

    /* file upload button */
    input[type='file']::file-selector-button {
        border-radius: 4px;
        padding: 0 16px;
        height: 20px;
        cursor: pointer;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        margin-right: 16px;
        transition: background-color 200ms;
    }

    /* file upload button hover state */
    input[type='file']::file-selector-button:hover {
        background-color: #f3f4f6;
    }

    /* file upload button active state */
    input[type='file']::file-selector-button:active {
        background-color: #e5e7eb;
    }
}

.warning {
    color: $pending-color;

    svg {
        fill: $pending-color;
    }
}

.error {
    color: $default-badge-color;

    svg {
        fill: $default-badge-color;
    }
}

.success {
    color: $succes-color;

    svg {
        fill: $succes-color;
    }
}

.chipsBox {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > * {
        margin-bottom: 8px;
        margin-right: 8px;
    }
}

.greenIcon {
    fill: $succes-color;
    rotate: 180deg;

    path {
        fill: $succes-color;
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex;
}

.justifyCenter {
    display: flex;
    justify-content: center;
}

.justifyBetween {
    display: flex;
    justify-content: space-between;
}

.justifyEnd {
    display: flex;
    justify-content: flex-end;
}

.alignCenter {
    display: flex;
    align-items: center;
}

.alignBaseline {
    display: flex;
    align-items: baseline;
}

.mr1 {
    margin-right: 8px !important;
}

.mr2 {
    margin-right: 16px !important;
}

.ml {
    margin-left: 4px !important;
}

.ml1 {
    margin-left: 8px !important;
}

.ml2 {
    margin-left: 16px !important;
}

.ml4 {
    margin-left: 32px !important;
}

.mt0 {
    margin-top: 0 !important;
}

.mt1 {
    margin-top: 8px !important;
}

.mt2 {
    margin-top: 16px !important;
}

.mb {
    margin-bottom: 4px !important;
}

.mb1 {
    margin-bottom: 8px !important;
}

.mb2 {
    margin-bottom: 16px !important;
}

.darkGrey {
    color: #808080;
}

.gap4 {
    gap: 4px;
}

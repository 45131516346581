@import '../../../../assets/style/variables';

.container {
    .menu {
        width: 200px;
        height: 40px;
        color: black;
        justify-content: flex-start;
        border: 1px solid $primary-border-color;
        border-radius: 8px;
        padding: 0;
        background-color: $white-color;

        &:hover {
            background-color: $white-color;
        }

        .welcome {
            margin-left: 24px;
        }

        .avatar {
            margin-left: 8px;
            height: 24px;
            width: 24px;
        }

        .badge {
            margin-left: 8px;
            width: 8px;
            height: 8px;

            display: flex;
            align-items: center;

            float: right;

            > span {
                border: 1px solid $white-color;
                left: 10px;
                top: -5px;
                min-width: 12px;
                padding: 0;
                height: 12px;
                width: 12px;
            }

            .expandIconRoot {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .menuOpen {
        background: white !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-color: transparent !important;
        z-index: 1 !important;
    }

    .dropdown {
        width: 200px;

        :global(.MuiPaper-root) {
            border-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-color: $white-color !important;
            background-color: $white-color;
            box-shadow: 0px 10px 40px rgba(44, 62, 80, 0.25);
        }
    }
}

.menuItem {
    color: $primary-color !important;

    &:hover {
        color: $label-text-color !important;
    }
}

.arrow {
    position: absolute;
    right: 12px;
}

.flip {
    transform: rotate(180deg);
}

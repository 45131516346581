$primary-color: #3745f2;
$primary-hover-color: #7c85fc;
$primary-font-family: 'Montserrat', sans-serif;
$primary-border-color: #d5e3ff;

$white-color: #fff;
$primary-text-color: #323232;
$secondary-text-color: #828282;
$label-text-color: #6a9bff;
$default-avatar-color: #7b61ff;
$default-dark-color: #323232;
$default-error-color: #e55151;
$default-border-color: #e6eefe;
$default-light-background: #f8fbff;
$default-background: #f1f6ff;
$default-input-text-color: #6a9bff;
$light-border-color: #eef5ff;
$light-blue: #edf4ff;
$dark-grey: #808080;

$succes-color: #6fd8b2;
$pending-color: #feb803;
$disabled-color: #979797;

$default-border-radius: 16px;
$default-badge-color: #e55151;

$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$dashboard-breakpoint-mobile: 1100px;
$breakpoint-xl: 1536px;

$html-font-size: 16px;
$notification-icon-space: 56px;

@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}

@function rem($pxValue) {
    @return #{calc(stripUnit($pxValue) / stripUnit($html-font-size))}rem;
}

$header-height: 63px;
$header-navigation-height: 56px;

$header-background-color: rgba(238, 245, 255, 0.5);
$header-navigation-background-color: rgba(248, 251, 255, 0.6);

$scrollbar-width: 8px;

.text-error {
    color: red;
    // border: 1px solid red;
    padding: 2px 4px;
    border-radius: 8px;
}

.text-green {
    color: green;
    // border: 1px solid green;
    padding: 2px 4px;
    border-radius: 8px;
}

.text-warning {
    color: orange;
    // border: 1px solid orange;
    padding: 2px 4px;
    border-radius: 8px;
}

@import '../../assets/style/variables';

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // 24px layout padding (top and bottom)
    height: calc(100vh - $header-height - $header-navigation-height - 24px - 24px);

    .content {
        max-width: 470px;
        text-align: center;

        h1 {
            color: $label-text-color;
            padding: 32px 0;
        }
    }

    img {
        max-width: 240px;
        margin: 35px 0;
        float: left;
    }
}
